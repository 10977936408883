import * as React from 'react';
import OfferingViewImage from './OfferingViewImage/OfferingViewImage';
import OfferingInfo from '../OfferingInfo/OfferingInfo';
import { IOfferingViewModel } from '../../domain/offering-view-model-factory';
import {
  ImageShapeOptions,
  OfferingListLayoutOptions,
} from '../../../Shared/appKeys/SettingsKeys';
import { DEFAULT_IMAGE_CONTAINER } from './OfferingView.const';
import { st, classes } from './OfferingView.st.css';
import {
  BiLoggerProps,
  withBiLoggerContext,
} from '../context/bi-logger-context';
import { WIDGET_BI_REFERRAL } from '../../adapters/reporting/bi-logger/bi.const';
import { Card } from 'wix-ui-tpa/Card';
import { OverlappingCard } from 'wix-ui-tpa/OverlappingCard';
import { StripCard } from 'wix-ui-tpa/StripCard';
import { OfferingIntent } from '../../platform/navigation/navigation.const';
import {
  OfferingCallbacksProps,
  withOfferingCallbacksContext,
} from '../context/offering-callbacks-context';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../context/running-environment-context';
import { CardRatioOptions } from 'wix-ui-tpa/dist/src/components/Card/Card';

interface OfferingViewProps {
  offeringViewModel: IOfferingViewModel;
}

interface OfferingImageDimensions {
  height: number;
  width: number;
}

interface OfferingViewState {
  imageDimensions: OfferingImageDimensions;
  dimensionsUpdateAttempts: number;
}

interface CardProps {
  media;
  info;
  isMobile: boolean;
}

class ClassicOfferingView extends React.Component<
  OfferingViewProps & CardProps
> {
  render() {
    const { media, info, isMobile, offeringViewModel } = this.props;

    return (
      <Card
        data-hook="offering-view"
        media={media}
        info={info}
        className={st(classes.classic, { mobile: isMobile })}
        ratio={(offeringViewModel.ratio as unknown) as CardRatioOptions}
        flippedRatio={offeringViewModel.ratioFlipped}
        invertInfoPosition={offeringViewModel.invertInfoPosition}
        stacked={isMobile}
        mediaAspectRatio={offeringViewModel.image.aspectRatio}
      />
    );
  }
}

class GridOfferingView extends React.Component<OfferingViewProps & CardProps> {
  render() {
    const { media, info, offeringViewModel, isMobile } = this.props;
    const roundMedia =
      offeringViewModel.image.shape === ImageShapeOptions.ROUND;
    return (
      <Card
        data-hook="offering-view"
        media={media}
        info={info}
        className={st(classes.grid, {
          roundMedia,
          stacked: true,
          mobile: isMobile,
        })}
        // roundMedia={roundMedia}
        flippedRatio={offeringViewModel.ratioFlipped}
        stacked={true}
        mediaAspectRatio={offeringViewModel.image.aspectRatio}
      />
    );
  }
}

class OverlappingOfferingView extends React.Component<
  OfferingViewProps & CardProps
> {
  render() {
    const { media, info, offeringViewModel } = this.props;

    return (
      <OverlappingCard
        data-hook="offering-view"
        media={media}
        info={info}
        className={st(classes.overlapping)}
        invertInfoPosition={offeringViewModel.invertInfoPosition}
      />
    );
  }
}

class StripOfferingView extends React.Component<OfferingViewProps & CardProps> {
  render() {
    const { media, info, offeringViewModel } = this.props;

    return (
      <StripCard
        data-hook="offering-view"
        media={media}
        info={info}
        className={st(classes.strip)}
        roundMedia={offeringViewModel.image.shape === ImageShapeOptions.ROUND}
      />
    );
  }
}

class OfferingView extends React.Component<
  {
    offeringViewImageRef?: React.RefObject<any>;
    imageDimensions?: OfferingImageDimensions;
    isImageDimensionsCalculatedByParent: boolean;
    isImageVisible: boolean;
  } & OfferingViewProps &
    BiLoggerProps &
    OfferingCallbacksProps &
    RunningEnvironmentProps,
  OfferingViewState
> {
  public static Image;
  private readonly offeringViewImageRef;
  displayName = 'OfferingView';

  constructor(props) {
    super(props);
    this.offeringViewImageRef = props.isImageDimensionsCalculatedByParent
      ? props.offeringViewImageRef
      : React.createRef();
    this.state = {
      imageDimensions: DEFAULT_IMAGE_CONTAINER,
      dimensionsUpdateAttempts: 0,
    };
    this.imageClickHandler = this.imageClickHandler.bind(this);
    this.updateImageDimensions = this.updateImageDimensions.bind(this);
  }

  private updateImageDimensions(): void {
    if (
      !this.props.isImageDimensionsCalculatedByParent &&
      this.offeringViewImageRef.current &&
      this.offeringViewImageRef.current.getClientRects()[0]
    ) {
      const {
        width,
        height,
      } = this.offeringViewImageRef.current.getClientRects()[0];
      if (height === 0 && this.state.dimensionsUpdateAttempts < 2) {
        setTimeout(this.updateImageDimensions, 200);
        this.setState({
          dimensionsUpdateAttempts: this.state.dimensionsUpdateAttempts + 1,
        });
      } else {
        this.setState({ imageDimensions: { width, height } });
      }
    }
  }

  imageClickHandler() {
    this.logViewImageClicked();
    this.onOfferingSelected();
  }

  logViewImageClicked() {
    const { offeringViewModel, biLoggerDriver } = this.props;
    biLoggerDriver.sendWidgetClick(
      offeringViewModel.id,
      offeringViewModel.type,
      offeringViewModel.isPendingApprovalFlow,
      WIDGET_BI_REFERRAL.WIDGET_IMAGE,
    );
  }

  onOfferingSelected() {
    const { offeringCallbacks, offeringViewModel } = this.props;
    offeringCallbacks.onOfferingSelected(
      offeringViewModel.id,
      OfferingIntent.SHOW_DETAILS,
      offeringViewModel.locationId,
    );
  }

  componentDidMount() {
    this.updateImageDimensions();
  }

  private getCardComponent() {
    switch (this.props.offeringViewModel.layout) {
      case OfferingListLayoutOptions.OVERLAPPING:
        return OverlappingOfferingView;
      case OfferingListLayoutOptions.STRIP:
        return StripOfferingView;
      case OfferingListLayoutOptions.GRID:
        return GridOfferingView;
      case OfferingListLayoutOptions.CLASSIC:
      default:
        return ClassicOfferingView;
    }
  }

  componentDidUpdate() {
    if (
      !this.props.isImageDimensionsCalculatedByParent &&
      this.offeringViewImageRef.current?.getClientRects()[0]?.height !==
        this.state.imageDimensions.height
    ) {
      this.updateImageDimensions();
    }
  }

  render() {
    const {
      offeringViewModel,
      runningEnvironment,
      isImageDimensionsCalculatedByParent,
      imageDimensions,
      isImageVisible,
    } = this.props;
    const { isMobile } = runningEnvironment;
    const CardComponent = this.getCardComponent();
    const { height, width } = isImageDimensionsCalculatedByParent
      ? imageDimensions
      : this.state.imageDimensions;

    return (
      <CardComponent
        offeringViewModel={offeringViewModel}
        media={
          isImageVisible ? (
            <OfferingViewImage
              forwardedRef={this.offeringViewImageRef}
              onClick={this.imageClickHandler}
              height={height}
              width={width}
              imageViewModel={offeringViewModel.image}
              layout={offeringViewModel.layout}
            />
          ) : null
        }
        info={<OfferingInfo offeringViewModel={offeringViewModel} />}
        isMobile={isMobile}
      />
    );
  }
}

export default withRunningEnvironmentContext(
  withOfferingCallbacksContext(withBiLoggerContext(OfferingView)),
);
